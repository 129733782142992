import React from "react";
import {AccountBoxOutlined, HomeOutlined,} from "@mui/icons-material";
import {Tabs} from "shared/TabsContainer";
import {FooterTabsContainer, FooterTabsContainerProps, FooterTabsContainerState} from "shared/FooterTabsContainer";
import {PathComponent, PathProps} from "index";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../shared/BaseFragment";
import {Box, ButtonBase, Card} from "@mui/material";
import {App} from "./App";
import {DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../shared/BaseApp";
import {AboutFragment} from "../shared/AboutFragment";
import {PD_SM, SZ_SM} from "../shared/dimens";
import {UserFragment} from "../shared/UserFragment";
import {UserCache, UserProfilePhoto} from "../shared/types";
import {getAuth} from "@firebase/auth";
import {GamesFragment} from "./games/GamesFragment";
import {PeopleFragment} from "./people/PeopleFragment";

export enum TabType {
  GAMES = "games",
  PEOPLE = "people",
}

const TABS: Tabs<TabType> = {
  items: [
    {
      type: TabType.GAMES,
      path: "games",
      text: "Games",
      iconType: HomeOutlined,
      render: (path: PathProps) => <GamesFragment path={path}/>,
    },
    {
      type: TabType.PEOPLE,
      path: "people",
      text: "People",
      iconType: AccountBoxOutlined,
      render: (path: PathProps) => <PeopleFragment path={path}/>,
    },
  ],
  containerId: "main",
};

type MainTabsContainerProps = FooterTabsContainerProps<TabType> & {}

type MainTabsContainerState = FooterTabsContainerState<TabType> & {}

class MainTabsContainer extends FooterTabsContainer<TabType, MainTabsContainerProps, MainTabsContainerState> {

  private readonly auth = getAuth();

  componentDidMount() {
    this.fetchUser();
  }

  private async fetchUser() {
    const user = await UserCache.getInstance().getUser(this.auth.currentUser.uid);
    this.setState({
      user: user,
    });
  }

  protected renderToolbar(): React.ReactElement {
    return <>
      <ButtonBase
        style={{pointerEvents: "all"}}
        onClick={() => App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
          <AboutFragment/>)}
      ><img src="/icon.png" style={{height: 48}}/>
      </ButtonBase>
      <span style={{flexGrow: 1, pointerEvents: "none"}}/>
      <Box style={{display: "flex", alignItems: "center", gap: PD_SM, pointerEvents: "all"}}>
        {/*<Button*/}
        {/*  color="primary"*/}
        {/*  onClick={() => {*/}
        {/*    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>*/}
        {/*      <PlayPassFragment/>);*/}
        {/*  }}>*/}
        {/*  <LocalActivityOutlined sx={{mr: 1}}/>*/}
        {/*  Get Play Pass*/}
        {/*</Button>*/}
        <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
          <ButtonBase
            onClick={() => App.CONTEXT.showDialog(
              null,
              () => <UserFragment uid={this.auth.currentUser.uid}/>)}
          >
            <img src={UserProfilePhoto(this.state.user)} style={{width: "100%", height: "100%"}}/>
          </ButtonBase>
        </Card>
      </Box>
    </>;
  }
}

export type MainProps = BaseFragmentProps & {}

type MainState = BaseFragmentState & {}

export class Main extends BaseFragment<MainProps, MainState> {

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {}
  }

  static nestedPaths(): PathComponent[] {
    return MainTabsContainer.nestedPathsFromTabs(TABS);
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <MainTabsContainer path={this.props.path} tabs={TABS}/>;
  }
}
