import {Action, EmptyConfig, ListItemChange, OnListItemsListener, UserProfilePhoto} from "shared/types";
import {Box, Button, TextField, Typography} from "@mui/material";
import {DW_XS, PAGE_FRAGMENT_SM_WIDTH, PD_LG, PD_MD, PD_XLG, PD_XXLG, SZ_LG, SZ_MD} from "shared/dimens";
import React, {ReactElement, useState} from "react";
import {App} from "app/App";
import {
  BaseApp,
  DIALOG_FLAG_ANIM_SLIDE,
  DIALOG_FLAG_SHOW_CLOSE,
  DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN
} from "shared/BaseApp";
import {CustomDialogContent} from "shared/Dialogs";
import {StyledBoxColumn, StyledListItem} from "shared/StyledComponents";
import {getMemberAuth} from "shared/auth";
import {Game, Layout, Room, ROOM_CODE_LENGTH, RoomGame, RoomGames, RoomJoin, RoomJoins, Rooms} from "app/types";
import {TicTacToeGameFragment} from "./TicTacToeGameFragment";
import {passkey} from "shared/passkey";
import {PathProps} from "index";
import {GAME_TIC_TAC_TOE} from "../../Games";
import {DrawOutlined} from "@mui/icons-material";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../../../shared/BaseFragment";
import {TicTacToeGameData} from "./types";

export function renderGameButton(action: Action) {
  return <Button
    variant="contained"
    onClick={action.onClick}>{action.text}</Button>
}

const DELAY = 1;

export class GameMark {

  static readonly VALUES = new Array<GameMark>(2);
  static readonly WHITE = GameMark.VALUES[0] = new GameMark("x", "X");
  static readonly BLACK = GameMark.VALUES[1] = new GameMark("o", "O");

  private constructor(readonly name: string, readonly text: string) {
  }

  getOther(): GameMark {
    return this.name === "x" ? GameMark.BLACK : GameMark.WHITE;
  }
}

function EnterRoomCodeView(props: { onRoomCodeEntered: (code: string) => void }): ReactElement {
  const [code, setCode] = useState<string>("");
  const enabled = code.length >= 6;
  const onKeyDown = event => {
    if (enabled && event.keyCode === 13) {
      event.preventDefault();
      props.onRoomCodeEntered(code);
    }
  };
  return <CustomDialogContent
    style={{minWidth: DW_XS, width: null}}
    title="Enter room code"
    customView={
      <StyledBoxColumn>
        <TextField
          autoFocus
          type="text"
          required
          onKeyDown={onKeyDown}
          inputProps={{
            style: {fontSize: "300%", fontWeight: "bold", textAlign: "center"},
            maxLength: ROOM_CODE_LENGTH,
          }}
          value={code}
          onChange={event => setCode(event.target.value?.trim().toUpperCase() || "")}/>
        <Button
          style={{
            fontSize: "150%",
            fontFamily: "Gabarito, sans-serif",
            paddingLeft: PD_XXLG,
            paddingRight: PD_XXLG
          }}
          disabled={code.length < ROOM_CODE_LENGTH}
          variant="contained"
          onClick={() => props.onRoomCodeEntered(code)}>
          Join room
        </Button>
      </StyledBoxColumn>
    }/>;
}

abstract class BaseGameHelper {

  constructor(readonly path: PathProps, readonly game: Game) {
  }

  private renderMarkButton(mark: GameMark) {
    return renderGameButton(new Action("Play as " + mark.text, () => {
      App.CONTEXT.hideDialog();
      setTimeout(() => this.onMarkSelected(mark), DELAY);
    }));
  }

  protected showChooseMarkDialog() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Choose mark"
        customView={
          <StyledBoxColumn>
            {GameMark.VALUES.map(mark => this.renderMarkButton(mark))}
          </StyledBoxColumn>
        }/>
    );
  }

  protected abstract onMarkSelected(mark: GameMark);
}

export type GamesFragmentProps = BaseFragmentProps & {
  onRoomSelected: (room: Room) => void,
}

type GamesFragmentState = BaseFragmentState & {
  rooms: Room[],
}

export class GamesFragment extends BaseFragment<GamesFragmentProps, GamesFragmentState> implements OnListItemsListener<RoomJoin> {

  private readonly memberId = getMemberAuth().getMemberId();
  private readonly loader = RoomJoins.getInstance();

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    if (forceReload) {
      await this.loader.loadListItems();
    } else {
      await this.loader.maybeLoadListItems();
    }
    const rooms: Room[] = [];
    for (const join of this.loader.getListItems()) {
      const room = await Rooms.getInstance().getOrLoadItem(join.id);
      if (room) {
        rooms.push(room);
      }
    }
    this.setState({
      rooms: rooms,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    RoomJoins.getInstance().registerObserver(this);
  }

  componentWillUnmount() {
    RoomJoins.getInstance().unregisterObserver(this);
    super.componentWillUnmount();
  }

  onItemChanged(item: RoomJoin, change: ListItemChange) {
    this.reload(true);
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      title: "No game joined",
      text: "When you join or create a game, it will show up here.",
      iconType: DrawOutlined,
    };
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <StyledBoxColumn style={{
      alignSelf: "center",
      width: "100%",
      maxWidth: PAGE_FRAGMENT_SM_WIDTH,
      padding: PD_LG,
      overflowY: "scroll"
    }}>
      {this.state.rooms?.length > 0
        ? this.state.rooms?.map(item => <StyledListItem
          img={UserProfilePhoto(item.creator === this.memberId ? item.joiner?.user : item.member.user)}
          title={item.id}
          onClick={() => this.props.onRoomSelected(item)}
        />) : this.renderEmpty()}
    </StyledBoxColumn>;
  }
}

export class NewGameHelper extends BaseGameHelper {

  constructor(path: PathProps) {
    super(path, GAME_TIC_TAC_TOE);
  }

  createNewGame() {
    const game = this.game;
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, () => {
        return <Box className="footer-tab-fragment-header"
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100vh",
                      flexDirection: "column",
                      position: "relative",
                      paddingBottom: SZ_LG,
                      background: game.color,
                    }}>
          <Box style={{
            display: "flex",
            flexShrink: 0,
            height: SZ_MD,
            alignItems: "center",
            paddingLeft: PD_MD,
            paddingRight: PD_MD,
            gap: PD_MD,
            left: 0,
            right: 0,
          }}>
            <Button style={{zIndex: 10000}} onClick={() => BaseApp.CONTEXT.hideDialog()}>Back</Button>
          </Box>
          <StyledBoxColumn
            style={{padding: PD_XLG, flexShrink: 0, alignItems: "center", justifyContent: "center", gap: PD_LG}}>
            <img src={game.icon} style={{width: 96}}/>
            <Typography style={{fontFamily: "Gabarito, sans-serif"}} variant="h4">{game.name}</Typography>
            <Typography>{game.description}</Typography>
            <StyledBoxColumn style={{width: 240}}>
              {renderGameButton(new Action("Create room code", () => {
                setTimeout(() => this.showChooseMarkDialog(), DELAY);
              }))}
              {renderGameButton(new Action("Enter room code", () => {
                setTimeout(() => this.showEnterRoomCodeDialog(), DELAY);
              }))}
            </StyledBoxColumn>
          </StyledBoxColumn>
          <GamesFragment path={this.path} onRoomSelected={room => {
            App.CONTEXT.hideDialog();
            setTimeout(() => App.CONTEXT.showFullscreenDialog(() => <TicTacToeGameFragment path={this.path}
                                                                                           initialRoom={room}/>), DELAY);
          }}/>
        </Box>;
      }
    );
  }

  private showEnterRoomCodeDialog() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <EnterRoomCodeView onRoomCodeEntered={code => {
        App.CONTEXT.hideDialog();
        setTimeout(() => this.joinAndShowNewRoom(code), DELAY);
      }}/>
    );
  }

  private async joinAndShowNewRoom(code: string) {
    const memberId = getMemberAuth().getMemberId();
    const room = await Rooms.getInstance().getOrLoadItem(code);
    if (!room) {
      App.CONTEXT.showToast("Room not found! Please try again.");
      return;
    }
    if (room.joinedBy && room.joinedBy !== memberId) {
      App.CONTEXT.showToast("Room is unavailable! Please try again.");
      return;
    }
    if (!room.joinedBy) {
      room.joinedBy = memberId;
      room.joinedAt = Date.now();
      room.joiner = getMemberAuth().member;
      await Rooms.getInstance().addListItem(room);
    }
    const join = await RoomJoins.getInstance().loadListItem(room.id);
    if (!join) {
      await RoomJoins.getInstance().addListItem(RoomJoin.createNew(room.id));
    }
    App.CONTEXT.showFullscreenDialog(() => <TicTacToeGameFragment path={this.path} initialRoom={room}/>);
  }

  protected onMarkSelected(mark: GameMark) {
    this.createAndShowNewRoom(mark);
  }

  private async createAndShowNewRoom(mark: GameMark) {
    const room = new Room(
      passkey({
        length: 6,
        type: "alphabetic_upper"
      }),
      getMemberAuth().getMemberId(),
      Date.now())
      .updateSelf(Layout.createNew(mark.name));
    await Rooms.getInstance().addListItem(room);
    await RoomJoins.getInstance().addListItem(RoomJoin.createNew(room.id));
    App.CONTEXT.showFullscreenDialog(() => <TicTacToeGameFragment path={this.path} initialRoom={room}/>);
  }
}


export class NewPageHelper extends BaseGameHelper {

  constructor(path: PathProps, private readonly room: Room) {
    super(path, GAME_TIC_TAC_TOE);
  }

  protected onMarkSelected(mark: GameMark) {
    this.updateRoomInternal(mark);
  }

  private async updateRoomInternal(mark: GameMark) {
    const memberId = getMemberAuth().getMemberId();
    if (this.room.creator !== memberId) {
      mark = mark.getOther();
    }
    await Rooms.getInstance().addListItem(this.room.clone<Room>(Room).updateSelf(Layout.createNew(mark.name)));
    await RoomGames.getInstance().addListItem(RoomGame.createNew(this.room.id, new TicTacToeGameData(mark.name)))
  }

  updateRoom() {
    this.showChooseMarkDialog();
  }
}