import {Game} from "./types";
import {colorGreen} from "../shared/colors";
import {NewGameHelper} from "./games/tictactoe/EditGameHelper";
import {PathProps} from "../index";

export enum GameTabType {
  TIC_TAC_TOE = "tic_tac_toe",
}

export const GAME_TIC_TAC_TOE: Game = {
  id: GameTabType.TIC_TAC_TOE,
  name: "Tic tac toe",
  description: "The classic game also knows as noughts & crosses.",
  icon: "/games/tictactoe/icon.png",
  color: colorGreen,
  show: (path: PathProps) => new NewGameHelper(path).createNewGame(),
};

export const GAMES: Game[] = [
  GAME_TIC_TAC_TOE,
];

export function getGameById(gameId: string): Game {
  return GAMES.find(game => game.id === gameId);
}