import React, {ReactElement} from "react";
import {Box, Button, ButtonBase, Typography} from "@mui/material";
import {BORDER_RADIUS, DIVIDER, DW_XS, PD_SM, PD_XLG, PD_XSM, PD_XXLG, SZ_LG, SZ_SM} from "../../shared/dimens";
import {StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "../../shared/StyledComponents";
import {getAuth} from "@firebase/auth";
import {Game, Room, RoomGame, RoomGames, Rooms} from "../types";
import {
  FullscreenDialogWithTitleFragment,
  FullscreenDialogWithTitleFragmentProps,
  FullscreenDialogWithTitleFragmentState
} from "../../shared/FullscreenDialogWithTitleFragment";
import {DIALOG_FLAG_ANIM_SLIDE, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {CustomDialogContent} from "../../shared/Dialogs";
import Confetti from "react-confetti";
import {colorBlue, colorYellow, white} from "../../shared/colors";
import {KeyboardDoubleArrowLeftOutlined} from "@mui/icons-material";
import {ListItemChange, OnListItemsListener} from "../../shared/types";
import {App} from "../App";
import {GameData} from "./types";

export function TurnIndicator() {
  return <StyledBoxRow style={{
    background: colorYellow,
    borderRadius: BORDER_RADIUS,
    paddingLeft: PD_SM,
    paddingRight: PD_SM,
    gap: 0,
    alignItems: "center"
  }}>
    <KeyboardDoubleArrowLeftOutlined/>
  </StyledBoxRow>;
}

function ViewRoomCodeView(props: { room: Room, onDismiss: () => void }): ReactElement {
  return <CustomDialogContent
    style={{minWidth: DW_XS, width: null}}
    title="Your room code"
    customView={
      <StyledBoxColumn>
        <Typography
          style={{fontSize: "400%", fontWeight: "bold", textAlign: "center"}}>
          {props.room.id}
        </Typography>
        {props.room.joinedBy
          ? <Typography style={{textAlign: "center"}}>Ask the other player to enter this code to join.</Typography>
          : null}
        <Button
          style={{
            fontSize: "150%",
            fontFamily: "Gabarito, sans-serif",
            paddingLeft: PD_XXLG,
            paddingRight: PD_XXLG
          }}
          variant="contained"
          onClick={() => props.onDismiss()}>
          Okay!
        </Button>
      </StyledBoxColumn>
    }/>;
}

export function CompletedGameView(props: { room: Room, title: string, text: string, image: string }): ReactElement {
  return <CustomDialogContent
    style={{width: DW_XS}}
    title={props.title}
    customView={
      <StyledBoxColumn style={{alignItems: "center", padding: PD_XLG, gap: PD_XLG}}>
        <img src={props.image} style={{width: 240}}/>
        <Typography
          style={{fontSize: "100%", fontWeight: "bold", textAlign: "center"}}>
          {props.text}
        </Typography>
      </StyledBoxColumn>
    }/>;
}

export type GameFragmentProps = FullscreenDialogWithTitleFragmentProps & {
  initialRoom: Room,
}

export type GameFragmentState<GD extends GameData> = FullscreenDialogWithTitleFragmentState & {
  showCompleted?: string,
  room: Room,
  data: GD,
}

export abstract class GameFragment<GD extends GameData, P extends GameFragmentProps, S extends GameFragmentState<GD>> extends FullscreenDialogWithTitleFragment<P, S> implements OnListItemsListener<RoomGame<GD>> {

  protected readonly auth = getAuth();

  protected onCreateState(): S {
    return {
      ...super.onCreateState(),
      room: this.props.initialRoom,
    };
  }

  getTitle(): string {
    return this.getGame().name;
  }

  protected abstract getGame(): Game;

  componentDidMount() {
    super.componentDidMount();
    RoomGames.getInstance().registerChildObserver(this.state.room.id, this);
    Rooms.getInstance().registerChildObserver(this.state.room.id, this);
  }

  componentWillUnmount() {
    RoomGames.getInstance().unregisterChildObserver(this.state.room.id, this);
    Rooms.getInstance().unregisterChildObserver(this.state.room.id, this);
  }

  onItemChanged(item: Room | RoomGame<any>, change: ListItemChange) {
    if (item instanceof Room) {
      if (item.id !== this.state.room.id) {
        return;
      }
      this.setState({
        room: item,
      });
    } else if (item instanceof RoomGame) {
      this.setState({
        data: item.data,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.room.layout?.id !== this.state.room.layout?.id) {
      this.onReset();
    } else {
      if (prevState.room.completedAt !== this.state.room.completedAt && this.state.room.completedAt) {
        this.setState({
          showCompleted: this.showCompletedDialog()
        });
        setTimeout(() => this.setState({showCompleted: null}), 5000);
      }
      if (Boolean(prevState.showCompleted) && !Boolean(this.state.showCompleted)) {
        this.hideDialog(prevState.showCompleted);
      }
    }
  }

  protected abstract showCompletedDialog(): string;

  protected abstract newGameData(): GD;

  protected async onReset() {
    const data = this.newGameData();
    this.setState({
      data: data,
    });
    await RoomGames.getInstance().addListItem(RoomGame.createNew(this.state.room.id, data));
  }

  renderContent(): React.ReactElement {
    return <Box style={{display: "flex", flexDirection: "column", flexGrow: 1, position: "relative"}}>
      {this.renderGameToolbar()}
      <StyledContainer>
        {this.renderGameContent()}
        {Boolean(this.state.showCompleted)
          ? <Confetti
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: "100%",
              zIndex: 1000
            }}
            run/>
          : null}
      </StyledContainer>
    </Box>;
  }

  private renderGameToolbar() {
    return <StyledBoxRow style={{
      boxSizing: "border-box",
      height: SZ_SM,
      paddingRight: PD_SM,
      borderBottom: DIVIDER,
      alignItems: "center"
    }}>
      <ButtonBase
        onClick={() => {
          App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
            <ViewRoomCodeView
              room={this.state.room}
              onDismiss={() => {
                App.CONTEXT.hideDialog();
              }}/>
          );
        }}>
        <StyledBoxColumn style={{
          height: SZ_SM,
          boxSizing: "border-box",
          flexShrink: 0,
          width: SZ_LG,
          gap: PD_XSM,
          padding: PD_SM,
          background: colorBlue,
          color: white,
          alignItems: "center",
          justifyContent: "center"
        }}>
          <Typography style={{fontWeight: "bold"}}>{this.state.room.id}</Typography>
        </StyledBoxColumn>
      </ButtonBase>
      {this.renderToolbarTitle()}
      <StyledSpan/>
      {this.renderToolbarButtons()}
    </StyledBoxRow>
  }

  protected renderToolbarTitle(): ReactElement {
    return null;
  }

  protected renderToolbarButtons(): ReactElement {
    return null;
  }

  protected abstract renderGameContent(): ReactElement;
}