import {GameData} from "../types";
import {JsonProperty} from "../../../common/json/json-property";

export class TicTacToeGameData extends GameData {

  @JsonProperty()
  readonly turn: string;

  @JsonProperty()
  readonly board: string[];

  constructor(turn: string = "x", board: string[] = new Array(9).fill("")) {
    super();
    this.turn = turn;
    this.board = board;
  }
}