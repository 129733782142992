import {FooterTabFragment, FooterTabFragmentProps, FooterTabFragmentState} from "../../shared/FooterTabFragment";
import {Grid, Typography} from "@mui/material";
import {StyledBoxColumn, StyledContainer} from "../../shared/StyledComponents";
import React from "react";
import {PD_LG} from "../../shared/dimens";
import {Game} from "../types";
import {Api} from "../api";
import {GameCard} from "../GameCard";
import {GAMES} from "../Games";

export type GamesFragmentProps = FooterTabFragmentProps & {}

export type GamesFragmentState = FooterTabFragmentState & {
}

export class GamesFragment extends FooterTabFragment<GamesFragmentProps, GamesFragmentState> {

  private readonly api = Api.getInstance();

  getTitle(): string {
    return "Games";
  }

  renderContent(): React.ReactElement {
    return <StyledContainer size="lg" style={{padding: PD_LG}}>
        <Grid container spacing={2}>
          {GAMES.map(game => <Grid item xs={12} sm={6} lg={4}>
              <GameCard game={game} onClick={() => this.showGame(game)}>
                <StyledBoxColumn style={{background: "white", padding: PD_LG}}>
                  <Typography style={{textAlign: "center", height: "4rem"}}>{game.description}</Typography>
                  {/*<Button variant="contained" style={{flexGrow: 1}} onClick={() => this.showGame(game)}>Play</Button>*/}
                </StyledBoxColumn>
                {/*<IconButton style={{position: "absolute", right: PD_MD, top: PD_MD}} onClick={(event) => {*/}
                {/*  event.stopPropagation();*/}
                {/*  App.CONTEXT.showActions(event.target as HTMLButtonElement, null, [*/}
                {/*    new Action("Stats", () => this.props.path.navigate("/stats/" + game.id)),*/}
                {/*    new Action("Charts", () => this.props.path.navigate("/charts/" + game.id)),*/}
                {/*  ]);*/}
                {/*}}>*/}
                {/*  <MoreHorizOutlined/>*/}
                {/*</IconButton>*/}
              </GameCard>
            </Grid>
          )}
        </Grid>
      </StyledContainer>;
  }

  private showGame(game: Game) {
    // BaseApp.CONTEXT.showTextDialog("Coming soon", "Stay tuned!", new Action("Okay!", () => BaseApp.CONTEXT.hideDialog()));
    game.show(this.props.path);
  }

}
