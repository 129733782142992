import React, {createRef, ReactElement} from "react";
import {Box, Button, List, ListItem, Typography} from "@mui/material";
import {StyledAvatar, StyledBoxColumn, StyledBoxRow, StyledSpan} from "shared/StyledComponents";
import {PD_LG, PD_MD} from "shared/dimens";
import {BaseApp, DIALOG_FLAG_ANIM_SLIDE, DIALOG_FLAG_SHOW_CLOSE} from "shared/BaseApp";
import {CompletedGameView, GameFragment, GameFragmentProps, GameFragmentState, TurnIndicator} from "../GameFragment";
import {AddOutlined, HelpOutlineOutlined} from "@mui/icons-material";

import {Game, Room, RoomGame, RoomGames, Rooms} from "../../types";
import {GAME_TIC_TAC_TOE} from "../../Games";
import {getMemberAuth} from "../../../shared/auth";
import {UserDisplayName} from "../../../shared/types";
import {TicTacToeGameData} from "./types";
import {NewPageHelper} from "./EditGameHelper";
import {App} from "../../App";

export type TicTacToeGameFragmentProps = GameFragmentProps & {}

type TicTacToeGameFragmentState = GameFragmentState<TicTacToeGameData> & {}

function $IDX(i: number, j: number): number {
  return i * 3 + j;
}

export class TicTacToeGameFragment extends GameFragment<TicTacToeGameData, TicTacToeGameFragmentProps, TicTacToeGameFragmentState> {

  private readonly canvasParentRef = createRef<HTMLDivElement>();
  private readonly canvasRef = createRef<HTMLCanvasElement>();

  private readonly img = new Map<string, HTMLImageElement>();

  protected getGame(): Game {
    return GAME_TIC_TAC_TOE;
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    async function loadImage(mark: string) {
      return await new Promise<HTMLImageElement>(resolve => {
        const img = window.document.createElement("img");
        img.onload = ev => resolve(img);
        img.src = "/games/tictactoe/" + mark + ".png";
      });
    }

    this.img.set("x", await loadImage("x"));
    this.img.set("o", await loadImage("o"));
    this.setState({
      data: (await RoomGames.getInstance<TicTacToeGameData>().getOrLoadItem(this.props.initialRoom.id))?.data || new TicTacToeGameData(),
    });
  }

  protected containerContentDidRender() {
    const divElement = this.canvasParentRef.current;
    this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    window.onresize = () => {
      this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    };
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  protected showCompletedDialog(): string {
    if (!this.isGameOver(this.state.data)) {
      return null;
    }
    let title: string, text: string, image: string;
    if (this.hasWinner(this.state.data)) {
      const didLose = this.playingMark() === this.state.data.turn;
      title = didLose ? "Uh oh..." : "Congratulations!";
      text = didLose ? "You lose." : "You win.";
      image = didLose ? "/images/thumbs_down.png" : "/images/thumbs_up.png";
    } else {
      title = "It's a draw";
      text = "Well done both.";
      image = "/images/hands.png";
    }
    return App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_ANIM_SLIDE},
      () => <CompletedGameView room={this.state.room} title={title} text={text} image={image}/>)
  }

  protected newGameData(): TicTacToeGameData {
    return new TicTacToeGameData();
  }

  private playingMark(): string {
    return getMemberAuth().getMemberId() === this.state.room.creator ? this.state.room.layout.creatorMark : this.otherTurn(this.state.room.layout.creatorMark);
  }

  protected renderToolbarTitle(): React.ReactElement {
    const room = this.state.room;
    const otherMember = room.creator === getMemberAuth().getMemberId() ? room.joiner : room.member;
    return <StyledBoxRow style={{alignItems: "center"}}>
      <StyledAvatar member={otherMember}/>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Typography>
          <b>{otherMember
            ? UserDisplayName(otherMember?.user)
            : "Waiting to join..."}
          </b>
        </Typography>
        {this.playingMark() !== this.state.data.turn
          ? <TurnIndicator/>
          : null}
        <StyledSpan/>
      </StyledBoxRow>
    </StyledBoxRow>
  }

  protected renderToolbarButtons() {
    return <>
      <Button onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE}, props => {
        return <StyledBoxColumn style={{padding: PD_LG}}>
          <Typography variant="h5">How to play Tic Tac Toe</Typography>
          <List>
            <ListItem>The game is played on a grid that's 3 squares by 3 squares.</ListItem>
            <ListItem>You are X , your friend is O (or vice versa). Players take turns putting their marks in empty
              squares.</ListItem>
            <ListItem>The first player to get 3 of her marks in a row (up, down, across, or diagonally) is the
              winner.</ListItem>
            <ListItem>When all 9 squares are full, the game is over. If no player has 3 marks in a row, the game ends in
              a tie.</ListItem>
          </List>
        </StyledBoxColumn>;
      })}>
        <HelpOutlineOutlined/>
      </Button>
      <Button variant="contained" onClick={(event) => new NewPageHelper(this.props.path, this.state.room).updateRoom()}>
        <AddOutlined/>
      </Button>
    </>
  }

  protected renderGameContent(): ReactElement | null {
    const me = getMemberAuth().member;
    const room = this.state.room;
    if (!room) {
      return null;
    }
    if (this.canvasRef.current) {
      this.renderBoard();
    }
    return <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_MD}}>
      <div ref={this.canvasParentRef} style={{width: "100%", maxWidth: 540}}>
        <canvas ref={this.canvasRef} onClick={(event) => {
          if (!this.state.room.joinedBy) {
            return;
          }
          if (this.isGameOver(this.state.data) || this.playingMark() !== this.state.data.turn) {
            return;
          }
          const rect = this.canvasRef.current.getBoundingClientRect();
          this.onBoardClick(event.clientX - rect.x, event.clientY - rect.y);
        }}/>
      </div>
    </Box>;
  }

  private resizeCanvas(width: number, height: number) {
    const current = this.canvasRef.current;
    if (current.width === width && current.height === height) {
      return;
    }
    current.width = width;
    current.height = height;
    this.forceUpdate();
  }

  private onBoardClick(x: number, y: number) {
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const unitSize = width / 3;
    const sx = Math.floor(x / unitSize);
    const sy = Math.floor(y / unitSize);
    if (sx < 0 || sy < 0 || sx >= 3 || sy >= 3) {
      return;
    }
    const index = $IDX(sy, sx);
    const val = this.state.data.board[index];
    if (Boolean(val)) {
      return;
    }
    const board = [...this.state.data.board];
    board[index] = this.state.data.turn;
    const data = new TicTacToeGameData(this.nextTurn(), board);
    this.setState({
      data: data,
    });
    RoomGames.getInstance<TicTacToeGameData>().addListItem(RoomGame.createNew(this.state.room.id, data));
    if (this.isGameOver(data)) {
      const room = this.state.room.clone<Room>(Room);
      room.completedAt = Date.now();
      Rooms.getInstance().addListItem(room);
    }
  }

  private isGameOver(data: TicTacToeGameData): boolean {
    if (this.hasWinner(data)) {
      return true;
    }
    for (const val of data.board) {
      if (!Boolean(val)) {
        return false;
      }
    }
    return true;
  }

  private hasWinner(data: TicTacToeGameData): boolean {
    const board = data.board;
    return (board[$IDX(0, 0)] && board[$IDX(0, 0)] === board[$IDX(1, 1)] && board[$IDX(0, 0)] === board[$IDX(2, 2)])
      || (board[$IDX(0, 2)] && board[$IDX(0, 2)] === board[$IDX(1, 1)] && board[$IDX(0, 2)] === board[$IDX(2, 0)])
      || (board[$IDX(0, 0)] && board[$IDX(0, 0)] === board[$IDX(0, 1)] && board[$IDX(0, 0)] === board[$IDX(0, 2)])
      || (board[$IDX(1, 0)] && board[$IDX(1, 0)] === board[$IDX(1, 1)] && board[$IDX(1, 0)] === board[$IDX(1, 2)])
      || (board[$IDX(2, 0)] && board[$IDX(2, 0)] === board[$IDX(2, 1)] && board[$IDX(2, 0)] === board[$IDX(2, 2)])
      || (board[$IDX(0, 0)] && board[$IDX(0, 0)] === board[$IDX(1, 0)] && board[$IDX(0, 0)] === board[$IDX(2, 0)])
      || (board[$IDX(0, 1)] && board[$IDX(0, 1)] === board[$IDX(1, 1)] && board[$IDX(0, 1)] === board[$IDX(2, 1)])
      || (board[$IDX(0, 2)] && board[$IDX(0, 2)] === board[$IDX(1, 2)] && board[$IDX(0, 2)] === board[$IDX(2, 2)]);
  }


  private nextTurn() {
    return this.otherTurn(this.state.data.turn);
  }

  private otherTurn(turn: string): "x" | "o" {
    return turn === "x" ? "o" : "x";
  }

  private renderBoard() {
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const scale = width / 600;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, width, height);

    const unitSize = width / 3;

    const innerLineWidth = scale * 2;
    ctx.strokeStyle = "black";
    ctx.lineWidth = innerLineWidth;
    for (let i = 1; i < 3; i++) {
      ctx.moveTo(0, i * unitSize);
      ctx.lineTo(width, i * unitSize);
      ctx.stroke();
      ctx.moveTo(i * unitSize, 0);
      ctx.lineTo(i * unitSize, height);
      ctx.stroke();
    }
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        const index = $IDX(i, j);
        const val = this.state.data.board[index];
        if (val === "x" || val === "o") {
          const image = this.img.get(val);
          ctx.drawImage(image, 0, 0, image.width, image.height, j * unitSize, i * unitSize, unitSize, unitSize);
        }
      }
    }

    const outerLineWidth = scale * 4;
    ctx.strokeStyle = "black";
    ctx.lineWidth = outerLineWidth;
    ctx.strokeRect(outerLineWidth / 2, outerLineWidth / 2, width - outerLineWidth, height - outerLineWidth);
  }
}